<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>客服热线</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="overflow: auto">
      <el-row>
        <el-col :span="24">
        <div class="grid-content bg-purple-dark">
            <el-button class="el-buttonr" size="mini" type="primary" @click="dialogVisible = true" >新增客服热线</el-button>
          </div>
          </el-col>
      </el-row>
      <!-- 分类搜索 -->
      <el-row style="margin-top: 15px">
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="城市"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!--商品数据展示区域-->
      <el-table
        :data="tableData"
        style="width: 750px"
      
        ref="singleTable"
        highlight-current-row
        border
        row-key="id"
        stripe
      >
        <el-table-column label="序号" width="50" />
        <el-table-column  prop="name" label="城市" width="250"  show-overflow-tooltip />
        <el-table-column prop="mobile" label="客服热线" width="150">
          <template slot-scope="scope">
            <p v-for="i in scope.row.nam" :key="i">{{ i }}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="200" fixed="right">
          <el-button @click="dialogVisible = true" size="mini" type="primary">编辑</el-button>
          <el-button @click="dialogVisible = true" size="mini" type="danger" >停用</el-button>
          </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      title="新增客服热线"
      class="abow_dialog"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
      :before-close="handleClose"
    >
      <el-form>
        <el-row>
          <el-form-item label="业务" required>
            <el-checkbox v-model="checked1">电商</el-checkbox>
            <el-checkbox v-model="checked2">代驾</el-checkbox>
          </el-form-item>
          <el-form-item label="城市" required>
            <v-distpicker
              @province="onprovince"
              @city="oncity"
              class="v-distpicker"
              :province="queryInfo.province"
              :city="queryInfo.city"
              hide-area
            ></v-distpicker>
          </el-form-item>
        </el-row>
        <el-button class="el-buttonr" size="mini" type="primary" @click="dialogVisible = true" >新增</el-button>
        <el-row style="margin-top: 10px">
          <p style="float: left; margin-top: 5px">
            <svg
              style="width: 15px; height: 15px; padding: 0 20px 0 0"
              t="1647071827451"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2214"
              width="200"
              height="200"
            >
              <path
                style="margin-top: -20px"
                d="M507.904 52.224q95.232 0 179.2 36.352t145.92 98.304 98.304 145.408 36.352 178.688-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-178.688-36.352-145.408-98.304-98.304-145.92-36.352-179.2 36.352-178.688 98.304-145.408 145.408-98.304 178.688-36.352zM736.256 573.44q30.72 0 55.296-15.872t24.576-47.616q0-30.72-24.576-45.568t-55.296-14.848l-452.608 0q-30.72 0-56.32 14.848t-25.6 45.568q0 31.744 25.6 47.616t56.32 15.872l452.608 0z"
                p-id="2215"
                fill="#d81e06"
              ></path>
            </svg>
          </p>
          <el-col :span="3.3">
            <el-input placeholder="客服电话" size="mini"></el-input>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="innerVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        time: "2021-04-23 11:18:54",
        province: "内蒙古自治区",
        city: "兴安盟",
      },
      tableData: [],
    };
  },

  methods: {
    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
};
</script>

<style scoped lang="scss">
.v-distpicker ::v-deep select {
  height: 35px !important;

  padding-top: 0.4rem;
  font-size: 14px;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
</style>
